import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const GalleryPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const nodes = data.allFile.nodes;

  return (
    <Layout title={siteTitle} location={ location }>
      <Seo title="Gallery" keywords={[`devon rex`, `devon rex cats`, `cats`, `kittens`, `devon rex kittens`, `devon rex breeder`]} />

      <article className="page-template no-image">
        <h3>Gallery</h3>
        <p className="gallery-text">All of these cats have gone to their new homes. Check out <Link to='/kittens'>Available Kittens</Link> page to see availability.</p>
        <div className="gallery-container">
          { nodes.map((node, i) => (
            <GatsbyImage 
              key={ `kitten-image-${ i }` } 
              image={node.childImageSharp.gatsbyImageData} 
              className="image-card"
            />
          ) )}
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 500, height: 500)
        }
      }
    }
  }
`

const Component = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <GalleryPage location={props.location} data={data} {...props} />
    )}
  />
)

export default Component;